import React from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { getMoviedetails } from "../../../firebase";
import "../home/css/style.css";
import Movieshome from "../home/movieshome";
import MovieSlider from "./movieslider";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { buildStyles } from "react-circular-progressbar";
import RelatedMovies from "./relatedmovies";

const ShowList = () => {
  const [moviedata, setMoviedata] = React.useState([]);
  const [videosection, setVideoSection] = React.useState([]);
  const [movietabdata, setMovietabdata] = React.useState("");
  const [book, setBook] = React.useState([]);
  const [stream, setStream] = React.useState([]);
  const [reviews, setReviews] = React.useState([]);
  let { slug } = useParams();

  React.useEffect(() => {
    getMoviedetails(slug)
      .then((data) => {
        const PropData = [];
        data.forEach((item) => {
          PropData.push(item?.data());
        });
        setMovietabdata(PropData[0].movie_url);
        setVideoSection(PropData[0].videos);
        setBook(PropData[0].book);
        setStream(PropData[0].stream);
        setReviews(PropData[0].reviews);
        setMoviedata(PropData[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log("Movie Data=>", videosection);

  return (
    <React.Fragment>
      <section>
        <MovieSlider />
      </section>
      <div className="main-content-details">
        <section id="iq-favorites">
          <Container fluid>
            <div className="block-space">
              <Row>
                <Col sm="12" className="overflow-hidden">
                  <iframe
                    width="500"
                    height="550px"
                    src={`${movietabdata}`}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className="overflow-hidden">
                  <div id="upcoming-contens">
                    <div id="prev" className="swiper-button swiper-button-prev">
                      <i className="fa fa-chevron-left"></i>
                    </div>
                    <div id="next" className="swiper-button swiper-button-next">
                      <i className="fa fa-chevron-right"></i>
                    </div>
                    <Swiper
                      slidesPerView={5}
                      spaceBetween={10}
                      navigation={{
                        prevEl: "#prev",
                        nextEl: "#next",
                      }}
                      loop={true}
                      breakpoints={{
                        320: { slidesPerView: 1 },
                        550: { slidesPerView: 2 },
                        991: { slidesPerView: 3 },
                        1400: { slidesPerView: 4 },
                      }}
                      className="favorites-slider list-inline  row p-0 m-0"
                    >
                      {videosection.map((data, index) => (
                        <SwiperSlide key={index} className="slide-item">
                          <div className="block-images1 block-images position-relative">
                            <div className="img-box">
                              <embed
                                type="video/webm"
                                src={data}
                                width="300"
                                height="200"
                              ></embed>
                            </div>
                            <div
                              onClick={() => setMovietabdata(data)}
                              className="block-description"
                            >
                              <div className="hover-buttons">
                                <span className="btn btn-hover">
                                  <i
                                    className="fa fa-play mr-1"
                                    aria-hidden="true"
                                  ></i>
                                  Play Now
                                </span>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
        <section className="movie-detail container-fluid ">
          <Row>
            <Col lg="8" md="12" sm="12" className="mb-3">
              <h3 className="head-movie-details">Book, Stream and Reviews</h3>
            </Col>
          </Row>
          <Tabs
            defaultActiveKey="Book"
            id="uncontrolled-tab-example"
            className="mb-3"
            style={{ fontWeight: "400" }}
          >
            <Tab eventKey="Book" title="Book">
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>Book source</th>
                    <th>Link</th>
                  </tr>
                </thead>
                {book?.map((data, index) => (
                  <tbody>
                    <tr>
                      <td>{data.source}</td>
                      <td> 
                        <a href={data.link} target="_blank">
                          <Button variant="primary" className="header-cta">
                            Book Now
                          </Button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </Table>
            </Tab>
            <Tab eventKey="Stream" title="Stream">
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>Stream source</th>
                    <th>Link</th>
                  </tr>
                </thead>
                {stream?.map((data, index) => (
                  <tbody>
                    <tr>
                      <td>{data.source}</td>
                      <td>
                        <a href= {data.link} target="_blank" rel="noreferrer">
                          <Button variant="primary" className="header-cta">
                            Stream Now
                          </Button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </Table>
            </Tab>
            <Tab eventKey="Reviews" title="Reviews">
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>Reviews</th>
                    <th>Ratings</th>
                    <th>Link</th>
                  </tr>
                </thead>
                {reviews?.map((data, index) => (
                  <tbody>
                    <tr>
                      <td>{data.source}</td>
                      <td>
                        <div style={{ width: 50, height: 50 }}>
                          <CircularProgressbar
                            value={data.value}
                            text={`${data.text}`}
                            styles={buildStyles({
                              textColor: "#fff",
                              pathColor: "#F04d21",
                              trailColor: "transparent",
                              textSize: "25px",
                            })}
                          />{" "}
                        </div>
                      </td>
                      <td>
                        <td>
                          <a href={data.link} target="_blank" rel="noreferrer">
                            <Button variant="primary" className="header-cta">
                              Read Now
                            </Button>
                          </a>
                        </td>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </Table>
            </Tab>
          </Tabs>
        </section>

        <section
          id="iq-upcoming-movie"
          className="iq-rtl-direction trending-info g-border"
        >
          <RelatedMovies />
        </section>
      </div>
    </React.Fragment>
  );
};
export default ShowList;
