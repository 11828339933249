import React, { useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import "../home/css/style.css";
// import CustomToggle from '../../../components/dropdowns'

//img
import aboutus1 from "../../../assets/images/division.png";
import { Link } from "react-router-dom";
import { BiCameraMovie } from "react-icons/bi";
import { BsDistributeVertical } from "react-icons/bs";
import { BsUpcScan } from "react-icons/bs";
import { RiDvdLine } from "react-icons/ri";
import { IoColorWandOutline } from "react-icons/io5";
import { MdOutlineSubtitles } from "react-icons/md";
import { MdOutlineRestore } from "react-icons/md";
import { BsArchive } from "react-icons/bs";

const Divisions = () => {
  const [faq, setfaq] = useState("1");

  return (
    <>
      <div
        className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50"
        style={{ backgroundImage: `url(${aboutus1})` }}
      >
        <Container fluid>
          <Row className="align-items-center">
            <Col sm="12">
              <nav
                aria-label="breadcrumb"
                className="text-center iq-breadcrumb-two"
              >
                <h2 className="title">Divisions</h2>
                <Breadcrumb className="main-bg">
                  <Breadcrumb.Item>Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Divisions</Breadcrumb.Item>
                </Breadcrumb>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <main id="main" className="site-main">
        <Container fluid>
          <Row className="btn-division">
            <Col>
              <Link to="/film-distribution">
                <BiCameraMovie className="icon-division" />
                <h3 className="h3-division">Film Distribution</h3>
              </Link>
            </Col>
            <Col>
              <Link to="/conversion">
                <BsDistributeVertical className="icon-division" />
                <h3 className="h3-division">Conversion</h3>{" "}
              </Link>
            </Col>
            <Col>
              <Link to="/film-negative-scanning">
                <BsUpcScan className="icon-division" />
                <h3 className="h3-division" style={{ textAlign: "center" }}>
                  Film Negative Scanning
                </h3>{" "}
              </Link>
            </Col>
            <Col>
              <Link to="/restoration">
                <MdOutlineRestore className="icon-division" />
                <h3 className="h3-division">Restoration</h3>{" "}
              </Link>
            </Col>
          </Row>
          <Row className="btn-division">
            <Col>
              <Link to="/blueray">
                <RiDvdLine className="icon-division" />
                <h3 className="h3-division" style={{ textAlign: "center" }}>
                  DVD, Blu-ray Mastering
                </h3>{" "}
              </Link>
            </Col>
            <Col>
              <Link to="/colorgrading">
                <IoColorWandOutline className="icon-division" />
                <h3 className="h3-division">Color Grading</h3>{" "}
              </Link>
            </Col>
            <Col>
              <Link to="/subtitling">
                <MdOutlineSubtitles className="icon-division" />
                <h3 className="h3-division">Subtitling</h3>{" "}
              </Link>
            </Col>
            <Col>
              <Link to="/archiving">
                <BsArchive className="icon-division" />
                <h3 className="h3-division">Archiving</h3>{" "}
              </Link>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
};

export default Divisions;
