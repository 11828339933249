import React, { useState } from "react";
import { gsap } from "gsap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";
import logo from "../../../assets/images/logo.png";
import { getMoviedetails } from "../../../firebase";
import { useParams } from "react-router-dom";
import VideoModal from "./VideoModal ";

const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: "",
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);

      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);

      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);

      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");

      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
      };

      return setOption;
    } else {
      return { opacity: 0 };
    }
  },
  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.from(elem, setOption);
  },

  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.to(elem, setOption);
  },

  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    const setEndOption = gsapAnimate.getData(elem);

    setEndOption.opacity = 1;

    setEndOption.x = 0;

    setEndOption.y = 0;

    setEndOption.rotate = 0;

    setEndOption.scale = 1;

    gsap.fromTo(elem, setOption, setEndOption);
  },
  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

const MovieSlider = () => {
  const { slug } = useParams();
  const [toggler1, setToggler1] = useState(false);
  const [sliderdata, setSliderdata] = React.useState([]);
  const [trailerid, setTrailerid] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  React.useEffect(() => {
    getMoviedetails(slug)
      .then((data) => {
        const PropData = [];
        data.forEach((item) => {
          PropData.push(item?.data());
        });
        console.log(PropData);
        setSliderdata(PropData);
        const videoId = PropData[0].movie_trailer
          .split("embed/")[1]
          .split("?")[0];
        setTrailerid(videoId);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const animationInit = () => {
    if (
      document.querySelector(".swiper-container .swiper-slide-active") !== null
    ) {
      const gsapElem = document
        .querySelector(".swiper-container .swiper-slide-active")
        .querySelectorAll('[data-iq-gsap="onStart"]');
      Array.from(gsapElem, (elem) => {
        return gsapAnimate.onStartEnd(elem);
      });
    }
  };
 
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <Swiper
        navigation={{
          prevEl: "#prev5",
          nextEl: "#next5",
        }}
        pagination={{
          clickable: true,
        }}
        onInit={() => {
          animationInit();
        }}
        onSlideChangeTransitionStart={() => animationInit()}
        loop={true}
        id="home-slider"
        className="slider m-0 p-0"
      >
        {sliderdata.map((data) => (
          <SwiperSlide
            key={data.id}
            className="slide slick-bg"
            style={{ backgroundImage: `url(${data.banner})` }}
          >
            <Container fluid className="position-relative h-100">
              <div className="slider-inner h-100">
                <Row className="align-items-center  iq-ltr-direction h-100 ">
                  <Col xl="6" lg="12" md="12">
                    <h1
                      className="slider-text big-title title text-uppercase"
                      data-iq-gsap="onStart"
                      data-iq-position-x="-200"
                    >
                      {data.movie_title}
                    </h1>
                    <div className="d-flex flex-wrap align-items-center">
                      {data.rating_star && (
                        <div
                          className="slider-ratting d-flex align-items-center mr-4 mt-2 mt-md-3"
                          data-iq-gsap="onStart"
                          data-iq-position-x="-200"
                          data-iq-delay="-0.5"
                        >
                          <ul className="ratting-start p-0 m-0 list-inline text-primary d-flex align-items-center justify-content-left">
                            {[...Array(data.rating_star)].map((index) => (
                              <li>
                                <i
                                  className="fa fa-star"
                                  key={index}
                                  aria-hidden="true"
                                  style={{ color: "rgb(245,197,24)" }}
                                ></i>
                              </li>
                            ))}
                          </ul>
                          {/* <span className="text-white ml-2">
                            {data.rating_star} ( {data.rating_from} )
                          </span> */}
                          <span
                            className="text-white ml-1"
                            style={{ display: "flex" }}
                          >
                            {data.rating_star}{" "}
                            {
                              <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/IMDB_Logo_2016.svg/575px-IMDB_Logo_2016.svg.png"
                                style={{ width: "50px", paddingLeft: "10px" }}
                              />
                            }{" "}
                          </span>
                        </div>
                      )}

                      <div
                        className="d-flex align-items-center mt-2 mt-md-3"
                        data-iq-gsap="onStart"
                        data-iq-position-x="-200"
                        data-iq-delay="-0.5"
                      >
                        {data.adult && (
                          <span
                            className="badge badge-secondary p-2"
                            style={{
                              backgroundColor: "#1e1e1e40",
                              border: "0.5px solid #fff",
                            }}
                          >
                            U/A
                          </span>
                        )}
                        {data.season && <span className="ml-3">Trending</span>}
                      </div>
                    </div>
                    <p
                        data-iq-gsap="onStart"
                        data-iq-position-y="80"
                        data-iq-delay="0.8"
                      >
                        {data.movie_description}
                      </p>
                    <div
                      className="trending-list"
                      data-wp_object-in="fadeInUp"
                      data-delay-in="1.2"
                    >
                      <div className="text-primary title starring">
                        Starring:{" "}
                        <span className="text-body">{data.starring}</span>
                      </div>
                      <div className="text-primary title genres">
                        Genres: <span className="text-body">{data.genres}</span>
                      </div>
                      <div className="text-primary title tag">
                        Language:{" "}
                        <span className="text-body">{data.language}</span>
                      </div>
                    </div>
                    {/* <div className="d-flex align-items-center r-mb-23" data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8">
                                            <Link to={`/movie/${data.slug}`} className="btn btn-hover iq-button">
                                                <i className="fa fa-play mr-2" aria-hidden="true"></i>View Details
                                            </Link>                
                                                        </div> */}
                  </Col>
                  <Col
                    xl="5"
                    lg="12"
                    md="12"
                    className="trailor-video text-center"
                  >
                    <Link
                      onClick={(e) => {
                      e.preventDefault(); // Prevent default anchor behavior
                       openModal();
                      }}
                      className="video-open playbtn"
                    >
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="80px"
                        height="80px"
                        viewBox="0 0 213.7 213.7"
                        enableBackground="new 0 0 213.7 213.7"
                        xmlSpace="preserve"
                      >
                        <polygon
                          className="triangle"
                          fill="none"
                          strokeWidth="7"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          points="73.5,62.5 148.5,105.8 73.5,149.1 "
                        />
                        <circle
                          className="circle"
                          fill="none"
                          strokeWidth="7"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          cx="106.8"
                          cy="106.8"
                          r="103.3"
                        />
                      </svg>
                      <span className="w-trailor">Watch Trailer</span>
                    </Link>
                  </Col>
                </Row>
              </div>
            </Container>
          </SwiperSlide>
        ))}
      </Swiper>
      <VideoModal 
    isOpen={isModalOpen} 
    onClose={closeModal} 
    videoId={trailerid} 
  />
    </React.Fragment>
  );
};
export default MovieSlider;
