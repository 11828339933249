import React, { useState } from "react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { Container, Col, Row, Nav, Tab } from "react-bootstrap";
import FsLightbox from "fslightbox-react";
import Select from "react-select";
import Slider from "./slider";
import Moviecarousel from "./latestmovies";
import tamilheros from "../../../assets/images/tamilheros.png";
import "./css/style.css";
import Movieshome from "./movieshome";
import Subscribe from "./subscribe";
import "./css/style.css";
import Announcement from "./Announcement";
import Social from "./social";

// img
import logo from "../../../assets/images/logo.png";
import icon from "../../../assets/video/trailer.mp4";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: "",
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);

      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);

      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);

      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");

      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
      };

      return setOption;
    } else {
      return { opacity: 0 };
    }
  },
  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.from(elem, setOption);
  },

  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.to(elem, setOption);
  },

  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    const setEndOption = gsapAnimate.getData(elem);

    setEndOption.opacity = 1;

    setEndOption.x = 0;

    setEndOption.y = 0;

    setEndOption.rotate = 0;

    setEndOption.scale = 1;

    gsap.fromTo(elem, setOption, setEndOption);
  },
  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

const Homepage = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [toggler1, setToggler1] = useState(false);
  const [toggler2, setToggler2] = useState(false);
  const [toggler3, setToggler3] = useState(false);

  const options1 = [
    { value: "season 1", label: "Season 1" },
    { value: "season 2", label: "Season 2" },
    { value: "season 3", label: "Season 3" },
  ];

  const options2 = [
    { value: "season 1", label: "Season 1" },
    { value: "season 2", label: "Season 2" },
  ];

  return (
    <>
      <FsLightbox
        toggler={toggler1}
        sources={[
          <iframe
            src={icon}
            title=" "
            width="500px"
            height="200px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
      />
      <FsLightbox
        toggler={toggler2}
        sources={[
          <iframe
            src={icon}
            title=" "
            width="500px"
            height="200px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
      />
      <FsLightbox
        toggler={toggler3}
        sources={[
          <iframe
            src={icon}
            title=" "
            width="500px"
            height="200px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
      />
      <section id="home" className="iq-main-slider p-0 iq-rtl-direction">
        <Slider />
      </section>

      <div className="main-content">
        <section id="iq-favorites">
          <Moviecarousel />
        </section>

        {/* <section id="iq-upcoming-movie">

            </section>

            <section id="iq-suggestede" className="s-margin">
            
            </section>  */}

        <section id="parallex" className="parallax-window">
          <Container fluid className="h-100">
            <Row className="align-items-center justify-content-center h-100 parallaxt-details">
              <Col lg="6">
                <div className="third-section-img">
                  <img
                    src={tamilheros}
                    className="img-fluid w-100"
                    alt="bailey"
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="text-left">
                  <h1 style={{ paddingBottom: "20px" }}>What we do</h1>

                  <div className="movie-time d-flex align-items-center mb-2 iq-ltr-direction">
                    <Link to="/film-distribution">
                      <h5>Film distribution</h5>
                    </Link>
                  </div>
                  <p>
                    Our key business activities include financing, acquisition
                    and distribution of Tamil & Malayalam films in multiple
                    formats such as theatrical, television and digital media.
                  </p>

                  <div className="movie-time d-flex align-items-center mt-4 mb-2 iq-ltr-direction">
                    <Link to="/conversion">
                      <h5>Conversion</h5>
                    </Link>
                  </div>
                  <p>
                    We offer advanced format conversion from DPX, TIFF, TGA and
                    Image based formats to HD/2K/4K Apple Prores, XDCAM, H.264
                    and MPEG among others.{" "}
                  </p>
                  <div className="movie-time d-flex align-items-center mt-4 mb-2 iq-ltr-direction">
                    <Link to="/film-negative-scanning">
                      <h5>Film negative scanning</h5>
                    </Link>
                  </div>
                  <p>
                    Home Screen Entertainment is one of the key players in the
                    market to offer the latest and most advanced film scanning
                    services to digitise your 35mm negative and positive film
                    into DPX Files with the highest ‘4K’ resolution.
                  </p>

                  <div className="parallax-buttons mt-4">
                    <Link
                      to="/divisions"
                      className="btn btn-hover"
                      style={{ fontWeight: 500, backgroundColor: "#F04D21" }}
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      {/* <section id="iq-trending" className="s-margin">
               <Container fluid>
                  <Row>
                     <Col sm="12" className="overflow-hidden">
                        <div className="d-flex align-items-center justify-content-between">
                           <h4 className="main-title">Trending</h4>
                        </div>
                        <div id="trending-contens">
                           <div id="prev4" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                           <div id="next4" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div>
                           <Swiper as="ul"
                              thumbs={{ swiper: thumbsSwiper }}
                              centeredSlides={true}
                              centeredSlidesBounds={true}
                              navigation={{
                                 prevEl: '#prev4',
                                 nextEl: '#next4'
                              }}
                              slidesPerView={5}
                              spaceBetween={20}
                              breakpoints={{
                                 320: { slidesPerView: 1 },
                                 550: { slidesPerView: 2 },
                                 991: { slidesPerView: 3 },
                                 1400: { slidesPerView: 4 },
                                 1500: { slidesPerView: 5 }
                              }}
                              loop={true}
                              className="list-inline p-0 m-0 row align-items-center iq-rtl-direction">
                              <SwiperSlide as="li">
                                 <Link to="#">
                                    <div className="movie-slick position-relative">
                                       <img src={trending1} className="img-fluid" alt="" />
                                    </div>
                                 </Link>
                              </SwiperSlide>
                              <SwiperSlide as="li">
                                 <Link to="#">
                                    <div className="movie-slick position-relative">
                                       <img src={trending2} className="img-fluid" alt="" />
                                    </div>
                                 </Link>
                              </SwiperSlide>
                              <SwiperSlide as="li">
                                 <Link to="#">
                                    <div className="movie-slick position-relative">
                                       <img src={trending3} className="img-fluid" alt="" />
                                    </div>
                                 </Link>
                              </SwiperSlide>
                              <SwiperSlide as="li">
                                 <Link to="#">
                                    <div className="movie-slick position-relative">
                                       <img src={trending4} className="img-fluid" alt="" />
                                    </div>
                                 </Link>
                              </SwiperSlide>
                              <SwiperSlide as="li">
                                 <Link to="#">
                                    <div className="movie-slick position-relative">
                                       <img src={trending5} className="img-fluid" alt="" />
                                    </div>
                                 </Link>
                              </SwiperSlide>
                              <SwiperSlide as="li">
                                 <Link to="#">
                                    <div className="movie-slick position-relative">
                                       <img src={trending6} className="img-fluid" alt="" />
                                    </div>
                                 </Link>
                              </SwiperSlide>
                           </Swiper>
                        </div>
                        <div>
                           <Swiper onSwiper={setThumbsSwiper}
                              slidesPerView={1}
                              freeMode={true}
                              watchSlidesProgress={true}
                              id="trending-slider"
                              className="mt-3  list-inline p-0 m-0  d-flex align-items-center iq-rtl-direction">
                              <SwiperSlide as="li">
                                 <div className="tranding-block position-relative"
                                    style={{ backgroundImage: `url(${trending1})` }}>
                                    <Tab.Container defaultActiveKey="trending-data1" className="trending-custom-tab">
                                       <div className="tab-title-info position-relative iq-ltr-direction">
                                          <Nav as="ul" variant="pills" className="trending-pills nav-pills d-flex justify-content-center align-items-center text-center iq-ltr-direction">
                                             <Nav.Item as="li" className="nav-item">
                                                <Nav.Link href="" eventKey="trending-data1">Overview</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item as="li" className="nav-item">
                                                <Nav.Link href="" eventKey="trending-data2">Episodes</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item as="li" className="nav-item">
                                                <Nav.Link href="" eventKey="trending-data3">Trailers</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item as="li" className="nav-item">
                                                <Nav.Link href="" eventKey="trending-data4">Similar Like This</Nav.Link>
                                             </Nav.Item>
                                          </Nav>
                                       </div>
                                       <Tab.Content className="trending-content">
                                          <Tab.Pane eventKey="trending-data1" className="overlay-tab show fade">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="res-logo">
                                                      <div className="channel-logo">
                                                         <img src={logo} className="c-logo" alt="streamit" />
                                                      </div>
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">the hero camp</h1>
                                                <div className="d-flex align-items-center text-white text-detail">
                                                   <span className="badge badge-secondary p-3">18+</span>
                                                   <span className="ml-3">3 Seasons</span>
                                                   <span className="trending-year">2020</span>
                                                </div>
                                                <div className="d-flex align-items-center series mb-4">
                                                   <Link to="#"><img src={trendinglabel} className="img-fluid" alt="" /></Link>
                                                   <span className="text-gold ml-3">#2 in Series Today</span>
                                                </div>
                                                <p className="trending-dec">
                                                   Lorem Ipsum is simply dummy text of the printing and typesetting
                                                   industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                                </p>
                                                <div className="p-btns">
                                                   <div className="d-flex align-items-center p-0">
                                                      <Link to="/show-details" className="btn btn-hover mr-2" tabIndex="0"><i className="fa fa-play mr-2" aria-hidden="true"></i>Play Now</Link>
                                                      <Link to="#" className="btn btn-link" tabIndex="0"><i className="ri-add-line"></i>My List</Link>
                                                   </div>
                                                </div>
                                                <div className="trending-list mt-4">
                                                   <div className="text-primary title">Starring:
                                                      <span className="text-body">Wagner Moura, Boyd Holbrook, Joanna</span>
                                                   </div>
                                                   <div className="text-primary title">Genres:
                                                      <span className="text-body">Crime, Action, Thriller, Biography</span>
                                                   </div>
                                                   <div className="text-primary title">This Is:
                                                      <span className="text-body">Violent, Forceful</span>
                                                   </div>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data2" className="overlay-tab  fade show " id="trending-episode1">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">the hero camp</h1>
                                                <div className="d-flex align-items-center text-white text-detail mb-4">
                                                   <span className="season_date ml-2">
                                                      2 Seasons
                                                   </span>
                                                   <span className="trending-year">Feb 2019</span>
                                                </div>
                                                <div className="iq-custom-select d-inline-block sea-epi">
                                                   <Select options={options1} id="f2" />

                                                </div>
                                                <div className="episodes-contens mt-4" id="episode1">
                                                   <div id="prev11" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                                   <div id="next11" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div>
                                                   <Swiper
                                                      spaceBetween={20}
                                                      navigation={{
                                                         prevEl: '#prev11',
                                                         nextEl: '#next11'
                                                      }}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 }
                                                      }}
                                                      loop={true}
                                                      className="list-inline p-0 m-0 iq-rtl-direction">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0"><i className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0"><i className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body ">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0"><i className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0"><i className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body ">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0"><i className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data3" className="overlay-tab fade show">
                                             <div
                                                className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">the hero camp</h1>
                                                <div className="episodes-contens mt-4" id="episode2">
                                                   <div id="prev12" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                                   <div id="next12" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div>
                                                   <Swiper
                                                      spaceBetween={20}
                                                      navigation={{
                                                         prevEl: '#prev12',
                                                         nextEl: '#next12'
                                                      }}
                                                      loop={true}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 },
                                                      }}
                                                      className="list-inline p-0 m-0 iq-rtl-direction">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0"><i className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0"><i
                                                                     className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0"><i
                                                                     className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0"><i className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0"><i className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data4" className="overlay-tab fade show">
                                             <div
                                                className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">the hero camp</h1>
                                                <div className="episodes-contens mt-4" id="episode3">
                                                   <div id="prev13" className="swiper-button swiper-button-prev"><i className="ri-arrow-left-s-line"></i></div>
                                                   <div id="next13" className="swiper-button swiper-button-next"><i className="ri-arrow-right-s-line"></i></div>
                                                   <Swiper
                                                      slidesPerView={4}
                                                      spaceBetween={20}
                                                      navigation={{
                                                         prevEl: '#prev13',
                                                         nextEl: '#next13'
                                                      }}
                                                      loop={true}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 },
                                                      }}
                                                      className="list-inline p-0 m-0 iq-rtl-direction">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0"><i
                                                                     className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0"><i className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0"><i className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0"><i className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0"><i className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                       </Tab.Content>
                                    </Tab.Container>
                                 </div>
                              </SwiperSlide>
                              <SwiperSlide as="li">
                                 <div className="tranding-block position-relative"
                                    style={{ backgroundImage: `url(${trending2})` }}>
                                    <Tab.Container defaultActiveKey="trending-data5" className="trending-custom-tab">
                                       <div className="tab-title-info position-relative">
                                          <Nav as="ul" variant="pills" className="trending-pills d-flex justify-content-center align-items-center text-center iq-ltr-direction">
                                             <Nav.Item as="li">
                                                <Nav.Link eventKey="trending-data5"
                                                   aria-selected="true">Overview</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item as="li">
                                                <Nav.Link eventKey="trending-data6"
                                                   aria-selected="true">Episodes</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item as="li">
                                                <Nav.Link eventKey="trending-data7"
                                                   aria-selected="true">Trailers</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item as="li">
                                                <Nav.Link eventKey="trending-data8"
                                                   aria-selected="true">Similar Like This</Nav.Link>
                                             </Nav.Item>
                                          </Nav>
                                       </div>
                                       <Tab.Content className="trending-content">
                                          <Tab.Pane eventKey="trending-data5" className="overlay-tab fade show">
                                             <div
                                                className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="res-logo">
                                                      <div className="channel-logo">
                                                         <img src={logo} className="c-logo" alt="streamit" />
                                                      </div>
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">The Appartment</h1>
                                                <div className="d-flex align-items-center text-white text-detail">
                                                   <span className="badge badge-secondary p-3">15+</span>
                                                   <span className="ml-3">2 Seasons</span>
                                                   <span className="trending-year">2020</span>
                                                </div>
                                                <div className="d-flex align-items-center series mb-4">
                                                   <Link to="#"><img src={trendinglabel}
                                                      className="img-fluid" alt="" /></Link>
                                                   <span className="text-gold ml-3">#2 in Series Today</span>
                                                </div>
                                                <p className="trending-dec">
                                                   Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                                                   dummy text ever since the 1500s.
                                                </p>
                                                <div className="p-btns">
                                                   <div className="d-flex align-items-center p-0">
                                                      <Link to="#" className="btn btn-hover mr-2" tabIndex="0">
                                                         <i className="fa fa-play mr-2" aria-hidden="true"></i>Play Now
                                                      </Link>
                                                      <Link to="#" className="btn btn-link" tabIndex="0">
                                                         <i className="ri-add-line"></i>My List
                                                      </Link>
                                                   </div>
                                                </div>
                                                <div className="trending-list mt-4">
                                                   <div className="text-primary title">Starring:
                                                      <span className="text-body">Wagner Moura, Boyd Holbrook, Joanna</span>
                                                   </div>
                                                   <div className="text-primary title">Genres:
                                                      <span className="text-body">Crime, Action, Thriller, Biography</span>
                                                   </div>
                                                   <div className="text-primary title">This Is:
                                                      <span className="text-body">Violent, Forceful</span>
                                                   </div>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data6" className="overlay-tab fade show">
                                             <div
                                                className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">The Appartment</h1>
                                                <div className="d-flex align-items-center text-white text-detail mb-4">
                                                   <span className="season_date ml-2">
                                                      2 Seasons
                                                   </span>
                                                   <span className="trending-year">Feb 2019</span>
                                                </div>
                                                <div className="iq-custom-select d-inline-block sea-epi">
                                                   <Select options={options2} id="f3" />

                                                </div>
                                                <div className="episodes-contens mt-4" id="episode1">
                                                   <div id="prev14" className="swiper-button swiper-button-prev"><i className="ri-arrow-left-s-line"></i></div>
                                                   <div id="next14" className="swiper-button swiper-button-next"><i className="ri-arrow-right-s-line"></i></div>
                                                   <Swiper
                                                      slidesPerView={4}
                                                      spaceBetween={20}
                                                      navigation={{
                                                         prevEl: '#prev14',
                                                         nextEl: '#next14'
                                                      }}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 },
                                                      }}
                                                      loop={true}
                                                      className="list-inline p-0 m-0 iq-rtl-direction">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>

                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0"><i
                                                                     className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0"><i
                                                                     className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0"><i className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0"><i className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0"><i className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data7" className="overlay-tab show fade">
                                             <div
                                                className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">The Appartment</h1>
                                                <div className="episodes-contens mt-4" id="episode2">
                                                   <div id="prev15" className="swiper-button swiper-button-prev"><i className="ri-arrow-left-s-line"></i></div>
                                                   <div id="next15" className="swiper-button swiper-button-next"><i className="ri-arrow-right-s-line"></i></div>
                                                   <Swiper
                                                      slidesPerView={4}
                                                      spaceBetween={20}
                                                      navigation={{
                                                         prevEl: '#prev15',
                                                         nextEl: '#next15'
                                                      }}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 },
                                                      }}
                                                      loop={true}
                                                      className="list-inline p-0 m-0">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0"><i className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data8" className="overlay-tab show fade">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">The Appartment</h1>
                                                <div className="episodes-contens mt-4" id="episode3">
                                                   <div id="prev16" className="swiper-button swiper-button-prev"><i className="ri-arrow-left-s-line"></i></div>
                                                   <div id="next16" className="swiper-button swiper-button-next"><i className="ri-arrow-right-s-line"></i></div>
                                                   <Swiper
                                                      slidesPerView={4}
                                                      spaceBetween={20}
                                                      navigation={{
                                                         prevEl: '#prev16',
                                                         nextEl: '#next16'
                                                      }}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 },
                                                      }}
                                                      loop={true}
                                                      className="list-inline p-0 m-0 iq-rtl-direction">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="#" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                       </Tab.Content>
                                    </Tab.Container>
                                 </div>
                              </SwiperSlide>
                              <SwiperSlide as="li">
                                 <div className="tranding-block position-relative"
                                    style={{ backgroundImage: `url(${trending3})` }}>
                                    <Tab.Container defaultActiveKey="trending-data9" className="trending-custom-tab">
                                       <div className="tab-title-info position-relative">
                                          <Nav as="ul" variant="pills" className="trending-pills d-flex justify-content-center align-items-center text-center iq-ltr-direction" role="tablist">
                                             <Nav.Item>
                                                <Nav.Link eventKey="trending-data9" aria-selected="true">Overview</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item>
                                                <Nav.Link eventKey="trending-data10" aria-selected="true">Episodes</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item>
                                                <Nav.Link eventKey="trending-data11" aria-selected="true">Trailers</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item>
                                                <Nav.Link eventKey="trending-data12" aria-selected="true">Similar Like This</Nav.Link>
                                             </Nav.Item>
                                          </Nav>
                                       </div>
                                       <Tab.Content className="trending-content">
                                          <Tab.Pane eventKey="trending-data9" className="overlay-tab fade show">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="res-logo">
                                                      <div className="channel-logo">
                                                         <img src={logo} className="c-logo" alt="streamit" />
                                                      </div>
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase ">the marshal king</h1>
                                                <div className="d-flex align-items-center text-white text-detail">
                                                   <span className="badge badge-secondary p-3">11+</span>
                                                   <span className="ml-3">3 Seasons</span>
                                                   <span className="trending-year">2020</span>
                                                </div>
                                                <div className="d-flex align-items-center series mb-4">
                                                   <Link to="#"><img src={trendinglabel} className="img-fluid" alt="" /></Link>
                                                   <span className="text-gold ml-3">#11 in Series Today</span>
                                                </div>
                                                <p className="trending-dec">
                                                   Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                                                   dummy text ever since the 1500s.
                                                </p>
                                                <div className="p-btns">
                                                   <div className="d-flex align-items-center p-0">
                                                      <Link to="#" className="btn btn-hover mr-2" tabIndex="0">
                                                         <i className="fa fa-play mr-2" aria-hidden="true"></i>
                                                         Play Now
                                                      </Link>
                                                      <Link to="#" className="btn btn-link" tabIndex="0">
                                                         <i className="ri-add-line"></i>
                                                         My List
                                                      </Link>
                                                   </div>
                                                </div>
                                                <div className="trending-list mt-4">
                                                   <div className="text-primary title">Starring:
                                                      <span className="text-body">Wagner
                                                         Moura, Boyd Holbrook, Joanna</span>
                                                   </div>
                                                   <div className="text-primary title">Genres:
                                                      <span className="text-body">Crime,
                                                         Action, Thriller, Biography</span>
                                                   </div>
                                                   <div className="text-primary title">This Is:
                                                      <span className="text-body">Violent,
                                                         Forceful</span>
                                                   </div>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data10" className="overlay-tab fade show">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">the marshal king</h1>
                                                <div className="d-flex align-items-center text-white text-detail mb-4">
                                                   <span className="season_date ml-2">
                                                      2 Seasons
                                                   </span>
                                                   <span className="trending-year">Feb 2019</span>
                                                </div>
                                                <div className="iq-custom-select d-inline-block sea-epi">
                                                   <Select options={options1} id="f4" />

                                                </div>
                                                <div className="episodes-contens mt-4" id="episode1">
                                                   <div id="prev17" className="swiper-button swiper-button-prev"><i className="ri-arrow-left-s-line"></i></div>
                                                   <div id="next17" className="swiper-button swiper-button-next"><i className="ri-arrow-right-s-line"></i></div>
                                                   <Swiper
                                                      slidesPerView={4}
                                                      spaceBetween={20}
                                                      navigation={{
                                                         prevEl: '#prev17',
                                                         nextEl: '#next17'
                                                      }}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 },
                                                      }}
                                                      loop={true}
                                                      className="list-inline p-0 m-0 iq-rtl-direction">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0"><i
                                                                     className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0"><i className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data11" className="overlay-tab fade show">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">the marshal king</h1>
                                                <div className="episodes-contens mt-4" id="episode2">
                                                   <div id="prev18" className="swiper-button swiper-button-prev"><i className="ri-arrow-left-s-line"></i></div>
                                                   <div id="next18" className="swiper-button swiper-button-next"><i className="ri-arrow-right-s-line"></i></div>
                                                   <Swiper
                                                      slidesPerView={4}
                                                      spaceBetween={20}
                                                      navigation={{
                                                         prevEl: '#prev18',
                                                         nextEl: '#next18'
                                                      }}
                                                      loop={true}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 },
                                                      }}
                                                      className="list-inline p-0 m-0 iq-rtl-direction">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="#" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0"><i
                                                                     className="ri-play-fill"></i></Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data12" className="overlay-tab show fade">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">the marshal king</h1>
                                                <div className="episodes-contens mt-4" id="episode3">
                                                   <div id="prev19" className="swiper-button swiper-button-prev"><i className="ri-arrow-left-s-line"></i></div>
                                                   <div id="next19" className="swiper-button swiper-button-next"><i className="ri-arrow-right-s-line"></i></div>
                                                   <Swiper
                                                      slidesPerView={4}
                                                      spaceBetween={20}
                                                      navigation={{
                                                         prevEl: '#prev19',
                                                         nextEl: '#next19'
                                                      }}
                                                      loop={true}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 },
                                                      }}
                                                      className="list-inline p-0 m-0 iq-rtl-direction">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                       </Tab.Content>
                                    </Tab.Container>
                                 </div>
                              </SwiperSlide>
                              <SwiperSlide as="li">
                                 <div className="tranding-block position-relative"
                                    style={{ backgroundImage: `url(${trending4})` }}>
                                    <Tab.Container defaultActiveKey="trending-data13" className="trending-custom-tab">
                                       <div className="tab-title-info position-relative">
                                          <Nav as="ul" variant="pills" className="trending-pills d-flex justify-content-center align-items-center text-center iq-ltr-direction">
                                             <Nav.Item>
                                                <Nav.Link eventKey="trending-data13" aria-selected="true">Overview</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item>
                                                <Nav.Link eventKey="trending-data14" aria-selected="true">Episodes</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item>
                                                <Nav.Link eventKey="trending-data15" aria-selected="true">Trailers</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item>
                                                <Nav.Link eventKey="trending-data16" aria-selected="true">Similar Like This</Nav.Link>
                                             </Nav.Item>
                                          </Nav>
                                       </div>
                                       <Tab.Content className="trending-content">
                                          <Tab.Pane eventKey="trending-data13" className="overlay-tab fade show">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="res-logo">
                                                      <div className="channel-logo">
                                                         <img src={logo} className="c-logo" alt="streamit" />
                                                      </div>
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase ">Dark Zone</h1>
                                                <div className="d-flex align-items-center text-white text-detail">
                                                   <span className="badge badge-secondary p-3">17+</span>
                                                   <span className="ml-3">1 Season</span>
                                                   <span className="trending-year">2020</span>
                                                </div>
                                                <div className="d-flex align-items-center series mb-4">
                                                   <Link to="#">
                                                      <img src={trendinglabel} className="img-fluid" alt="" />
                                                   </Link>
                                                   <span className="text-gold ml-3">#2 in Series Today</span>
                                                </div>
                                                <p className="trending-dec">
                                                   Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                                                   dummy text ever since the 1500s.
                                                </p>
                                                <div className="p-btns">
                                                   <div className="d-flex align-items-center p-0">
                                                      <Link to="#" className="btn btn-hover mr-2" tabIndex="0">
                                                         <i className="fa fa-play mr-2" aria-hidden="true"></i>Play Now
                                                      </Link>
                                                      <Link to="#" className="btn btn-link" tabIndex="0">
                                                         <i className="ri-add-line"></i>My List
                                                      </Link>
                                                   </div>
                                                </div>
                                                <div className="trending-list mt-4">
                                                   <div className="text-primary title">Starring:
                                                      <span className="text-body">Wagner
                                                         Moura, Boyd Holbrook, Joanna</span>
                                                   </div>
                                                   <div className="text-primary title">Genres:
                                                      <span className="text-body">Crime,
                                                         Action, Thriller, Biography</span>
                                                   </div>
                                                   <div className="text-primary title">This Is:
                                                      <span className="text-body">Violent,
                                                         Forceful</span>
                                                   </div>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data14" className="overlay-tab fade show">
                                             <div
                                                className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">Dark Zone</h1>
                                                <div className="d-flex align-items-center text-white text-detail mb-4">
                                                   <span className="season_date ml-2">
                                                      2 Seasons
                                                   </span>
                                                   <span className="trending-year">Feb 2019</span>
                                                </div>
                                                <div className="iq-custom-select d-inline-block sea-epi">
                                                   <Select options={options2} id="f5" />

                                                </div>
                                                <div className="episodes-contens mt-4" id="episode1">
                                                   <div id="prev20" className="swiper-button swiper-button-prev"><i className="ri-arrow-left-s-line"></i></div>
                                                   <div id="next20" className="swiper-button swiper-button-next"><i className="ri-arrow-right-s-line"></i></div>
                                                   <Swiper
                                                      slidesPerView={4}
                                                      spaceBetween={20}
                                                      navigation={{
                                                         prevEl: '#prev20',
                                                         nextEl: '#next20'
                                                      }}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 },
                                                      }}
                                                      loop={true}
                                                      className="list-inline p-0 m-0 iq-rtl-direction">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data15" className="overlay-tab show fade">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">Dark Zone</h1>
                                                <div className="episodes-contens mt-4" id="episode2">
                                                   <div id="prev21" className="swiper-button swiper-button-prev"><i className="ri-arrow-left-s-line"></i></div>
                                                   <div id="next21" className="swiper-button swiper-button-next"><i className="ri-arrow-right-s-line"></i></div>
                                                   <Swiper
                                                      slidesPerView={4}
                                                      spaceBetween={20}
                                                      navigation={{
                                                         prevEl: '#prev21',
                                                         nextEl: '#next21'
                                                      }}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 },
                                                      }}
                                                      loop={true}
                                                      className="list-inline p-0 m-0 iq-rtl-direction">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data16" className="overlay-tab show fade">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">Dark Zone</h1>
                                                <div className="episodes-contens mt-4" id="episode3">
                                                   <div id="prev22" className="swiper-button swiper-button-prev"><i className="ri-arrow-left-s-line"></i></div>
                                                   <div id="next22" className="swiper-button swiper-button-next"><i className="ri-arrow-right-s-line"></i></div>
                                                   <Swiper
                                                      slidesPerView={4}
                                                      spaceBetween={20}
                                                      navigation={{
                                                         prevEl: '#prev22',
                                                         nextEl: '#next22'
                                                      }}
                                                      loop={true}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 },
                                                      }}
                                                      className="list-inline p-0 m-0 iq-rtl-direction">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="#" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                       </Tab.Content>
                                    </Tab.Container>
                                 </div>
                              </SwiperSlide>
                              <SwiperSlide as="li">
                                 <div className="tranding-block position-relative"
                                    style={{ backgroundImage: `url(${trending5})` }}>
                                    <Tab.Container defaultActiveKey="trending-data17" className="trending-custom-tab">
                                       <div className="tab-title-info position-relative">
                                          <Nav as="ul" variant="pills" className="trending-pills d-flex justify-content-center align-items-center text-center iq-ltr-direction">
                                             <Nav.Item>
                                                <Nav.Link eventKey="trending-data17" aria-selected="true">Overview</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item>
                                                <Nav.Link eventKey="trending-data18" aria-selected="true">Episodes</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item>
                                                <Nav.Link eventKey="trending-data19" aria-selected="true">Trailers</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item>
                                                <Nav.Link eventKey="trending-data20" aria-selected="true">Similar Like This</Nav.Link>
                                             </Nav.Item>
                                          </Nav>
                                       </div>
                                       <Tab.Content className="trending-content">
                                          <Tab.Pane eventKey="trending-data17" className="overlay-tab fade show">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="res-logo">
                                                      <div className="channel-logo">
                                                         <img src={logo} className="c-logo" alt="streamit" />
                                                      </div>
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">Opposites Attract</h1>
                                                <div className="d-flex align-items-center text-white text-detail">
                                                   <span className="badge badge-secondary p-3">7+</span>
                                                   <span className="ml-3">2 Seasons</span>
                                                   <span className="trending-year">2020</span>
                                                </div>
                                                <div className="d-flex align-items-center series mb-4">
                                                   <Link to="#"><img src={trendinglabel} className="img-fluid" alt="" /></Link>
                                                   <span className="text-gold ml-3">#2 in Series Today</span>
                                                </div>
                                                <p className="trending-dec">
                                                   Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                                                   dummy text ever since the 1500s.
                                                </p>
                                                <div className="p-btns">
                                                   <div className="d-flex align-items-center p-0">
                                                      <Link to="#" className="btn btn-hover mr-2" tabIndex="0">
                                                         <i className="fa fa-play mr-2" aria-hidden="true"></i>Play Now
                                                      </Link>
                                                      <Link to="#" className="btn btn-link" tabIndex="0"><i className="ri-add-line"></i>
                                                         My List
                                                      </Link>
                                                   </div>
                                                </div>
                                                <div className="trending-list mt-4">
                                                   <div className="text-primary title">Starring:
                                                      <span className="text-body">Wagner
                                                         Moura, Boyd Holbrook, Joanna</span>
                                                   </div>
                                                   <div className="text-primary title">Genres:
                                                      <span className="text-body">Crime,
                                                         Action, Thriller, Biography</span>
                                                   </div>
                                                   <div className="text-primary title">This Is:
                                                      <span className="text-body">Violent,
                                                         Forceful</span>
                                                   </div>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data18" className="overlay-tab show fade">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">Opposites Attract</h1>
                                                <div className="d-flex align-items-center text-white text-detail mb-4">
                                                   <span className="season_date ml-2">
                                                      2 Seasons
                                                   </span>
                                                   <span className="trending-year">Feb 2019</span>
                                                </div>
                                                <div className="iq-custom-select d-inline-block sea-epi">
                                                   <Select options={options2} id="f6" />

                                                </div>
                                                <div className="episodes-contens mt-4" id="episode1">
                                                   <div id="prev23" className="swiper-button swiper-button-prev"><i className="ri-arrow-left-s-line"></i></div>
                                                   <div id="next23" className="swiper-button swiper-button-next"><i className="ri-arrow-right-s-line"></i></div>
                                                   <Swiper
                                                      slidesPerView={4}
                                                      spaceBetween={20}
                                                      navigation={{
                                                         prevEl: '#prev23',
                                                         nextEl: '#next23'
                                                      }}
                                                      loop={true}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 },
                                                      }}
                                                      className="list-inline p-0 m-0 iq-rtl-direction">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data19" className="overlay-tab show fade">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">Opposites Attract</h1>
                                                <div className="episodes-contens mt-4" id="episode2">
                                                   <div id="prev24" className="swiper-button swiper-button-prev"><i className="ri-arrow-left-s-line"></i></div>
                                                   <div id="next24" className="swiper-button swiper-button-next"><i className="ri-arrow-right-s-line"></i></div>
                                                   <Swiper
                                                      slidesPerView={4}
                                                      spaceBetween={20}
                                                      navigation={{
                                                         prevEl: '#prev24',
                                                         nextEl: '#next24'
                                                      }}
                                                      loop={true}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 },
                                                      }}
                                                      className="list-inline p-0 m-0 iq-rtl-direction">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data20" className="overlay-tab show fade">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">Opposites Attract</h1>
                                                <div className="episodes-contens mt-4" id="episode3">
                                                   <div id="prev24" className="swiper-button swiper-button-prev"><i className="ri-arrow-left-s-line"></i></div>
                                                   <div id="next24" className="swiper-button swiper-button-next"><i className="ri-arrow-right-s-line"></i></div>
                                                   <Swiper
                                                      slidesPerView={4}
                                                      spaceBetween={20}
                                                      navigation={{
                                                         pevEl: '#prev24',
                                                         nextEl: '#next24'
                                                      }}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 },
                                                      }}
                                                      loop={true}
                                                      className="list-inline p-0 m-0 iq-rtl-direction">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                       </Tab.Content>
                                    </Tab.Container>
                                 </div>
                              </SwiperSlide>
                              <SwiperSlide as="li">
                                 <div className="tranding-block position-relative"
                                    style={{ backgroundImage: `url(${trending6})` }}>
                                    <Tab.Container defaultActiveKey="trending-data21" className="trending-custom-tab">
                                       <div className="tab-title-info position-relative">
                                          <Nav as="ul" variant="pills" className="trending-pills d-flex justify-content-center align-items-center text-center iq-ltr-direction">
                                             <Nav.Item>
                                                <Nav.Link eventKey="trending-data21" aria-selected="true">Overview</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item>
                                                <Nav.Link eventKey="trending-data22" aria-selected="true">Episodes</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item>
                                                <Nav.Link eventKey="trending-data23" aria-selected="true">Trailers</Nav.Link>
                                             </Nav.Item>
                                             <Nav.Item>
                                                <Nav.Link eventKey="trending-data24" aria-selected="true">Similar Like This</Nav.Link>
                                             </Nav.Item>
                                          </Nav>
                                       </div>
                                       <Tab.Content className="trending-content">
                                          <Tab.Pane eventKey="trending-data21" className="overlay-tab fade show">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="res-logo">
                                                      <div className="channel-logo">
                                                         <img src={logo} className="c-logo" alt="streamit" />
                                                      </div>
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">Fire Storm</h1>
                                                <div className="d-flex align-items-center text-white text-detail">
                                                   <span className="badge badge-secondary p-3">17+</span>
                                                   <span className="ml-3">2 Seasons</span>
                                                   <span className="trending-year">2020</span>
                                                </div>
                                                <div className="d-flex align-items-center series mb-4">
                                                   <Link to="#">
                                                      <img src={trendinglabel} className="img-fluid" alt="" />
                                                   </Link>
                                                   <span className="text-gold ml-3">#2 in Series Today</span>
                                                </div>
                                                <p className="trending-dec">
                                                   Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                                                   dummy text ever since the 1500s.
                                                </p>
                                                <div className="p-btns">
                                                   <div className="d-flex align-items-center p-0">
                                                      <Link to="#" className="btn btn-hover mr-2" tabIndex="0">
                                                         <i className="fa fa-play mr-2" aria-hidden="true"></i>Play Now
                                                      </Link>
                                                      <Link to="#" className="btn btn-link" tabIndex="0">
                                                         <i className="ri-add-line"></i>My List
                                                      </Link>
                                                   </div>
                                                </div>
                                                <div className="trending-list mt-4">
                                                   <div className="text-primary title">Starring:
                                                      <span className="text-body">Wagner
                                                         Moura, Boyd Holbrook, Joanna</span>
                                                   </div>
                                                   <div className="text-primary title">Genres:
                                                      <span className="text-body">Crime,
                                                         Action, Thriller, Biography</span>
                                                   </div>
                                                   <div className="text-primary title">This Is:
                                                      <span className="text-body">Violent,
                                                         Forceful</span>
                                                   </div>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data22" className="overlay-tab show fade">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">Fire Storm</h1>
                                                <div className="d-flex align-items-center text-white text-detail mb-4">
                                                   <span className="season_date ml-2">
                                                      2 Seasons
                                                   </span>
                                                   <span className="trending-year">Feb 2019</span>
                                                </div>
                                                <div className="iq-custom-select d-inline-block sea-epi">
                                                   <Select options={options1} id="f7" />

                                                </div>
                                                <div className="episodes-contens mt-4" id="episode1">
                                                   <div id="prev25" className="swiper-button swiper-button-prev"><i className="ri-arrow-left-s-line"></i></div>
                                                   <div id="next25" className="swiper-button swiper-button-next"><i className="ri-arrow-right-s-line"></i></div>
                                                   <Swiper
                                                      slidesPerView={4}
                                                      spaceBetween={20}
                                                      navigation={{
                                                         prevEl: '#prev25',
                                                         nextEl: '#next25'
                                                      }}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 },
                                                      }}
                                                      loop={true}
                                                      className="list-inline p-0 m-0 iq-rtl-direction">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data23" className="overlay-tab show fade">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">Fire Storm</h1>
                                                <div className="episodes-contens mt-4" id="episode2">
                                                   <div id="prev26" className="swiper-button swiper-button-prev"><i className="ri-arrow-left-s-line"></i></div>
                                                   <div id="next26" className="swiper-button swiper-button-next"><i className="ri-arrow-right-s-line"></i></div>
                                                   <Swiper
                                                      slidesPerView={4}
                                                      spaceBetween={20}
                                                      navigation={{
                                                         prevEl: '#prev26',
                                                         nextEl: '#next26'
                                                      }}
                                                      loop={true}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 },
                                                      }}
                                                      className="list-inline p-0 m-0">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#" target="_blank">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" target="_blank" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#" target="_blank">Trailer 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="trending-data24" className="overlay-tab show fade">
                                             <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Link to="#" tabIndex="0">
                                                   <div className="channel-logo">
                                                      <img src={logo} className="c-logo" alt="stramit" />
                                                   </div>
                                                </Link>
                                                <h1 className="trending-text big-title text-uppercase">Fire Storm</h1>
                                                <div className="episodes-contens mt-4" id="episode3">
                                                   <div id="prev27" className="swiper-button swiper-button-prev"><i className="ri-arrow-left-s-line"></i></div>
                                                   <div id="next27" className="swiper-button swiper-button-next"><i className="ri-arrow-right-s-line"></i></div>
                                                   <Swiper
                                                      slidesPerView={4}
                                                      spaceBetween={20}
                                                      breakpoints={{
                                                         320: { slidesPerView: 1 },
                                                         550: { slidesPerView: 2 },
                                                         991: { slidesPerView: 3 },
                                                         1400: { slidesPerView: 4 },
                                                      }}
                                                      navigation={{
                                                         prevEl: '#prev27',
                                                         nextEl: '#next27'
                                                      }}
                                                      loop={true}
                                                      className="list-inline p-0 m-0 iq-rtl-direction">
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes1} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="#" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 1</Link>
                                                               <span className="text-primary">2.25 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes2} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 2</Link>
                                                               <span className="text-primary">3.23 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes3} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 3</Link>
                                                               <span className="text-primary">2 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes4} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 4</Link>
                                                               <span className="text-primary">1.12 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                      <SwiperSlide className="e-item">
                                                         <div className="block-image position-relative">
                                                            <Link to="#">
                                                               <img src={episodes5} className="img-fluid" alt="" />
                                                            </Link>
                                                            <div className="episode-play-info">
                                                               <div className="episode-play">
                                                                  <Link to="/show-details" tabIndex="0">
                                                                     <i className="ri-play-fill"></i>
                                                                  </Link>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="episodes-description text-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                               <Link to="#">Episode 5</Link>
                                                               <span className="text-primary">2.54 m</span>
                                                            </div>
                                                            <p className="mb-0">
                                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                                            </p>
                                                         </div>
                                                      </SwiperSlide>
                                                   </Swiper>
                                                </div>
                                             </div>
                                          </Tab.Pane>
                                       </Tab.Content>
                                    </Tab.Container>
                                 </div>
                              </SwiperSlide>
                           </Swiper>
                        </div>
                     </Col>
                  </Row>
               </Container>
                                                   </section> */}
      <div className="footer-content">
        <section id="iq-tvthrillers" className="all-movies-home">
          <Movieshome />
        </section>
        {/* <section className="announcement-section">
          <Announcement />
        </section> */}
        <section className="social-landing">
          <Social />
        </section>
        {/* <section id="iq-tvthrillers" className="s-margin mb-5">
          <Subscribe />
        </section> */}
      </div>
    </>
  );
};

export default Homepage;
