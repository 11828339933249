import { Switch, Route, useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Pricing from '../views/backend/main/pricing'

//blog
import Blog from '../views/backend/blog/blog';
import BlogDetail from '../views/backend/blog/blog-details';

//App
import UserProfile from '../views/backend/app/usermanagement/userprofile'
import UserAccountSettingList from '../views/backend/app/usermanagement/useraccountsetting'

//Extrapages
import FAQ from '../views/backend/pages/faq'
import TermsOfUse from '../views/backend/pages/extrapages/termsOfUse'
import PrivacyPolicy from '../views/backend/pages/extrapages/privacyPolicy'
import AboutUs from '../views/backend/pages/about-us'
import Contact from '../views/backend/pages/contact'
import PricingPlan2 from '../views/backend/pages/pricing/pricing2';

//Category
import CategoryList from '../views/backend/category/category-list';

//Movie
import AddMovie from '../views/backend/movie/add-movie';
import MovieList from '../views/backend/movie/movie-list';

//Show
import ShowList from '../views/backend/show/show-list';

// Home
import Homepage from '../views/backend/home/home'

// Announcement
import Announcementdetail from '../views/backend/show/announcement-detail';

// Divisions
import Divisions from '../views/backend/Divisions/divisions';
import FilmDistribution from '../views/backend/Divisions/filmdistribution';
import FilmNegativeScanning from '../views/backend/Divisions/film-negative-scanning';
import Conversion from '../views/backend/Divisions/conversion';
import Blueray from '../views/backend/Divisions/bluray';
import Colorgrading from '../views/backend/Divisions/colorgrading';
import Subtitling from '../views/backend/Divisions/Subtitling';
import Restoration from '../views/backend/Divisions/Restoration';
import Archiving from '../views/backend/Divisions/Archiving';
import GenreGallery from '../views/backend/movie/genre';
import ScrollToTop from '../components/scrolltotop';
import RelatedMovies from '../views/backend/show/relatedmovies';

const Layout1Route = () => {

    let location = useLocation();

    return (
        <TransitionGroup>
            <CSSTransition
                // key={location.key}
                classNames="fade"
                timeout={300}
            >
                <Switch location={location}>
                    <ScrollToTop>

                    <Route path="/pricing-plan-1" component={Pricing} />

                    {/* App */}
                    <Route path="/manage-profile" component={UserProfile} />
                    <Route path="/setting" component={UserAccountSettingList} />

                    {/* Blog */}
                    <Route path="/blog" component={Blog} />
                    <Route path="/blog-details" component={BlogDetail} />

                    {/* Extrapages */}
                    <Route path="/faq" component={FAQ} />
                    <Route path="/terms-of-service" component={TermsOfUse} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/about-us" component={AboutUs} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/pricing-plan-2" component={PricingPlan2} />

                    {/* Category */}
                    <Route path="/show-category" component={CategoryList} />

                    {/* Movie */}
                    <Route path="/movie-details" component={AddMovie} />
                    <Route path="/movies" component={MovieList} />

                    {/* Show */}
                    <Route path="/movie/:slug" component={ShowList} />

                    {/* homepage */}
                    <Route path="/" exact component={Homepage} />

                    {/* Announcement */}
                    <Route path="/announcement/:slug" component={Announcementdetail} />
                    
                    {/* Divisions */}
                    <Route path="/divisions" component={Divisions} />
                    <Route path="/film-distribution" component={FilmDistribution} />
                    <Route path="/film-negative-scanning" component={FilmNegativeScanning} />
                    <Route path="/conversion" component={Conversion} />
                    <Route path="/blueray" component={Blueray} />
                    <Route path="/colorgrading" component={Colorgrading} />
                    <Route path="/subtitling" component={Subtitling} />
                    <Route path="/restoration" component={Restoration} />
                    <Route path="/archiving" component={Archiving} />

                    {/* Genre */}
                    <Route path="/genre/:genre" component={GenreGallery} />

                    {/* Related Movies */}
                    <Route path="/related-movies" component={RelatedMovies} />
                    </ScrollToTop>
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default Layout1Route