import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { db } from "../../../firebase"; // Assuming this is the correct import path
import "../home/css/style.css";
import { query, getDocs, collection } from "firebase/firestore";

const MovieGallery = () => {
  const [moviedata, setMoviedata] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc');
  const [dateFilter, setDateFilter] = useState('');
  const [dateOptions, setDateOptions] = useState([]);

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const q = query(collection(db, 'movie'));
        const querySnapshot = await getDocs(q);
        let movies = querySnapshot.docs.map(doc => ({
          id: doc.id,
          createTime: doc._document.createTime.timestamp.seconds,
          ...doc.data()
        }));

        // Generate date options based on all movies
        const newDateOptions = generateDateOptions(movies);
        setDateOptions(newDateOptions);

        // Sorting
        movies.sort((a, b) => sortOrder === 'asc' ? a.createTime - b.createTime : b.createTime - a.createTime);

        // Filtering
        if (dateFilter) {
          const [filterYear, filterMonth] = dateFilter.split('-');
          movies = movies.filter(movie => {
            const movieDate = new Date(movie.createTime * 1000);
            const movieMonth = movieDate.getMonth().toString().padStart(2, '0');
            const movieYear = movieDate.getFullYear().toString();    
            const isSameMonth = movieYear === filterYear && movieMonth === filterMonth;
            //console.log(`Movie: ${movie.movie_title}, Movie Date: ${movieYear}-${movieMonth}, Filter: ${filterYear}-${filterMonth}, Include: ${isSameMonth}`);
            return isSameMonth;
          });
        }
        setMoviedata(movies);
      } catch (error) {
        console.error("Error fetching movies:", error);
      }
    };
  
    fetchMovies();
  }, [sortOrder, dateFilter]);

  const generateDateOptions = (movies) => {
    const uniqueDates = new Set();
    movies.forEach(movie => {
      const date = new Date(movie.createTime * 1000);
      const monthYear = `${date.getFullYear()}-${date.getMonth().toString().padStart(2, '0')}`;
      uniqueDates.add(monthYear);
    });
  
    return Array.from(uniqueDates).sort((a, b) => {
      // Sort by year and month in descending order
      return b.localeCompare(a);
    });
  }; 
  

  const createImageBox = (movie, index) => (
    <Col lg={2} md={3} sm={4} key={index}>
      <div className="image-box">
        <div className="image-box-image">
          <Link to={`/movie/${movie.slug}`}>
            <img
              src={movie.thumbnail ? movie.thumbnail : "https://via.placeholder.com/600x900"}
              alt={movie.movie_title || "Movie Image"}
            />
          </Link>
        </div>
        <div className="image-box-content">
          <h4 className="h4-title-gallery">
            <Link to={`/movie/${movie.slug}`}>{movie.movie_title}</Link>
          </h4>
          <p className="gallery-p my-1">{movie.language}</p>
          <div className="genre-gallery my-1" style={{ marginTop: "-10px" }}>
            <Link to={`/genre/${movie.genres}`} style={{ color: "#fff" }}>
              {movie.genres}
            </Link>
          </div>
        </div>
      </div>
    </Col>
  );

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col sm="12">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="gallery-movies-title">All Movies</h4>
              <div>
                {/* <select onChange={(e) => setSortOrder(e.target.value)}>
                  <option value="desc">Newest First</option>
                  <option value="asc">Oldest First</option>
                </select> */}
                <select onChange={(e) => setDateFilter(e.target.value)}>
                  <option value="">All Dates</option>
                  {dateOptions.map(option => {
                    const [year, month] = option.split('-');
                    const monthName = new Date(year, month).toLocaleString('default', { month: 'long' });
                    return <option value={option} key={option}>{`${monthName} ${year}`}</option>;
                  })}
                </select>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {moviedata.map((movie, index) => createImageBox(movie, index))}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default MovieGallery;
