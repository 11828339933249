import React, { useEffect, useState } from "react";
import "./social.css";
import { Link, BrowserRouter } from "react-router-dom";
import { TwitterTimelineEmbed, TwitterTweetEmbed } from "react-twitter-embed";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "react-loading-skeleton/dist/skeleton.css";
import { Instagram } from "react-content-loader";
import { Container, Row } from "react-bootstrap";
import { InstagramEmbed, FacebookEmbed } from "react-social-media-embed";

export default function Social() {
  const responsive = {
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
  };

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "//widget.tagembed.com/embed.min.js";
    script.async = true;
    script.type = "text/javascript";
    document.body.appendChild(script);
  }, []);

  //console.log(posts);

  return (
    <Container fluid className="dark-bg-color"> 
      <div className="social">
        <div className="social__container">
          <div className="social__container__title">
            <h1 className="social_title">Checkout our Social Community here</h1>
          </div>
        </div>
      </div>
      <div>
        <Row>
          <div class="col-md-4">
          <FacebookEmbed
          url="https://www.facebook.com/homescreenentertainment/"
          width={400}
         />
          </div>
          <div class="col-md-4">
          <div style={{ display: 'flex', justifyContent: 'center', height: '100%', width: '100%' }}>
           <InstagramEmbed url="https://www.instagram.com/homescreenentfze" width={400} />
          </div>
          </div>
          <div class="col-md-4">
          <TwitterTimelineEmbed
              sourceType="profile"
              screenName="homescreenent"
              options={{ height: 420 }}
            />
            {/*<div class="tagembed-socialwall" data-wall-id="21690" view-url="https://widget.tagembed.com/21690?view">  </div> */}
          </div>
        </Row>
        {/*<Row>
        <div class="col-md-4"><TwitterTimelineEmbed
          sourceType="profile"
          screenName="APIfilms"
          options={{ height: 570 }}
        /></div>
        <div class="col-md-4"> <TwitterTweetEmbed
          tweetId={'1596377651631239169'} /></div>
        <div class="col-md-4"><TwitterTweetEmbed
          tweetId={'1596377651631239169'} /></div>
        </Row>
     {/*} <Row>
        <div class="col-md-4"><Instagram backgroundColor={'#171630'} foregroundColor={'#141323'}/></div>
        <div class="col-md-4"><Instagram backgroundColor={'#171630'} foregroundColor={'#141323'}/></div>
        <div class="col-md-4"><Instagram backgroundColor={'#171630'} foregroundColor={'#141323'}/></div>
  </Row> */}
      </div>
    </Container>
  );
}
