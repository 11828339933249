import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getMoviesbyGenre } from "../../../firebase";
import '../home/css/style.css'
import { useParams } from "react-router-dom";



const GenreGallery = () => {


    const [moviedata, setMoviedata] = React.useState([]);
    const { genre } = useParams();

    React.useEffect(() => {
        getMoviesbyGenre(genre).then((data) => {
            const PropData = [];
            data.forEach((doc) => {
                PropData.push(doc.data());
            });
            setMoviedata(PropData);
            console.log(PropData)
        }).catch((error) => {
            console.log("Error getting documents: ", error);
        });
    }, [genre])
   
    const createImageBox = (moviedata, index) => (
        <Col lg={2} md={3} sm={4} key={index}>
            <div className="image-box">
                <div className="image-box-image">
                    <Link to={`/movie/${moviedata.slug}`}>
                        <img src={moviedata.thumbnail
                            ? moviedata.thumbnail
                            : "https://via.placeholder.com/600x900"} alt="" />
                    </Link>
                </div>
                <div className="image-box-content">
                    <h4 className="h4-title-gallery">
                        <Link to={`/movie/${moviedata.slug}`}>{moviedata.movie_title}</Link>
                    </h4>
                    <div className="genre-gallery">
                        <Link to={`/genre/${moviedata.genres}`}>{moviedata.genres}</Link>
                    </div>
                </div>
            </div>
        </Col>
    );



    return (
        <React.Fragment>
            <Container fluid>
                <Row className="genre-list">
                    <Col sm="12" className="overflow-hidden">
                        <div className="d-flex align-items-center justify-content-between">
                            <h4 className="gallery-movies-title">Movies by Genre : {genre}</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {moviedata.map((image, index) => createImageBox(image, index))}
                </Row>
             
            </Container>

            </React.Fragment>
    )
}

export default GenreGallery;