import React from 'react';

const VideoModal = ({ isOpen, videoId, onClose }) => {
    const modalStyle = {
        display: isOpen ? 'flex' : 'none', // Only show the modal when `isOpen` is true
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.8)',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    };

    const iframeStyle = {
        width: '80%', // Adjust width as needed
        height: '80vh', // Adjust height as needed
    };

    const closeButtonStyle = {
        position: 'absolute',
        top: '20px',
        right: '20px',
        background: '#ff0000',
        border: 'none',
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        color: '#fff',
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '32px',
        textAlign: 'center',
        cursor: 'pointer',
        zIndex: 1100,
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    };

    const videoSrc = `https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&modestbranding=1`;

    return (
        <>
            <style>
                {`
                    .close-modal {
                        position: absolute;
                        top: 20px;
                        right: 20px;
                        background: #ff0000;
                        border: none;
                        border-radius: 50%;
                        width: 32px;
                        height: 32px;
                        color: #fff;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 32px;
                        text-align: center;
                        cursor: pointer;
                        z-index: 1100;
                        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
                    }
                `}
            </style>
            <div style={modalStyle}>
                <iframe
                    src={videoSrc}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="video"
                    style={iframeStyle}
                />
                <button onClick={onClose} style={closeButtonStyle}>&times;</button>
            </div>
        </>
    );
};

export default VideoModal;
