import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import footer1 from "../../../../assets/images/SSLW.png";
import footer2 from "../../../../assets/images/footer/02.jpg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from "../../../../store/mode/rtlmode";
import Ap from "../../../../assets/images/APIntLogo.png";

const mapStateToProps = (state) => {
  return {
    rtlMode: getRtlMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      rtlModeAction,
    },
    dispatch
  ),
});

const FooterStyle = (props) => {
  return (
    <>
      <footer id="contact" className="footer-one iq-bg-dark">
        <div className="footer-top" style={{ paddingLeft: "6rem" }}>
          <Container fluid>
            <Row className="footer-standard">
              <Col lg="11">
                <div className="widget text-center ml-3">
                  <div className="menu-footer-link-1-container">
                    <ul
                      id="menu-footer-link-1"
                      className="menu p-0"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <li
                        id="menu-item-7314"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7314"
                        style={{ fontSize: "18px" }}
                      >
                        <Link to="/terms-of-service">Terms Of Use</Link>
                      </li>
                      <li
                        id="menu-item-7316"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7316"
                        style={{ paddingLeft: "1rem", fontSize: "18px" }}
                      >
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li
                        id="menu-item-7118"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118"
                        style={{ fontSize: "18px", paddingLeft: ".5rem" }}
                      >
                        <Link to="/about-us">About</Link>
                      </li>
                      <li
                        id="menu-item-7118"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118"
                        style={{ paddingLeft: "5rem", fontSize: "18px" }}
                      >
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={{ display: "flex", justifyContent: "space-around" }}>
              {/* <Col
                lg="4"
                md="6"
                className="mt-4 mt-lg-0"
                style={{ paddingLeft: "2rem" }}
              >
                <h6 className="footer-link-title">Follow Us </h6>
                <ul className="info-share">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/homescreenentertainment/"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://twitter.com/homescreenent?lang=en"
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/homescreenentertainment/?hl=en"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" to="#">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </Col> */}
              <Col
                lg="3"
                md="6"
                className="ml-0"
                style={{ paddingLeft: "2.3rem" }}
              >
                <div
                  className="widget text-left"
                  style={{ paddingLeft: 0, display: "flex" }}
                >
                  <div
                    className="textwidget"
                    style={{ paddingLeft: 0, display: "flex",alignContent:"center",alignItems:"center", }}
                  >
                    <h6
                      className="footer-link-title"
                      style={{ paddingLeft: ".5rem" ,marginTop:"20px"}}
                    >
                      Our Partners
                    </h6>

                    <div className="d-flex align-items-center">
                      <Link
                        className="app-image"
                        to="//simplysouth.tv/"
                        target="_blank"
                      >
                        <img
                          src={footer1}
                          alt="play-store"
                          style={{ width: "120px" }}
                        />
                      </Link>
                      <br />
                      {/* <Link
                        className="ml-3 app-image"
                        to="//apinternationalfilms.com/"
                        target="_blank"
                      >
                        <img
                          src={Ap}
                          alt="app-store"
                          style={{ width: "50px" }}
                        />
                      </Link> */}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <div
                className="widget text-center"
                style={{ paddingRight: "4rem" }}
              >
                <div className="textwidget">
                  <p>
                    <small style={{ fontWeight: "300" }}>
                      © 2023 Home Screen Entertainment. All Rights Reserved.{" "}
                    </small>
                  </p>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </footer>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterStyle);
