import React from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Dropdown,
  Form,
  Nav,
  Button,
  Modal,
  Table,
  Tab,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../../../components/Card";
import "../../../../views/backend/home/css/style.css";
import { useLocation, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CustomToggle from "../../../../components/dropdowns";

//img

import logo from "../../../../assets/images/logo2.png";
import thumb1 from "../../../../assets/images/notify/thumb-1.jpg";
import thumb2 from "../../../../assets/images/notify/thumb-2.jpg";
import thumb3 from "../../../../assets/images/notify/thumb-3.jpg";
import user from "../../../../assets/images/user/user.jpg";
import "../../../../views/backend/home/css/style.css";
import { getAllMovies } from "../../../../firebase";
// import { NodeNextRequest } from "next/dist/server/base-http/node";

const HeaderStyle1 = (props) => {
  //create a popup with a form to capture enquirey details
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [open, setOpen] = React.useState(false);
  let history = useHistory();
  /* search function */
  const [search, setSearch] = React.useState("");
  const [searchResult, setSearchResult] = React.useState([]);
  const [moviedata, setMoviedata] = React.useState([]);
  const [state, setState] = React.useState(false);
  const [data, setData] = React.useState([]);
  const location = useLocation();

  React.useEffect(() => {
    //getAllMovies returns all the docs in the collection
    getAllMovies()
      .then((data) => {
        const PropData = [];
        data.forEach((doc) => {
          PropData.push(doc.data());
        });
        console.log("PropData", PropData);
        setMoviedata(PropData);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    const path = location.pathname;
    if (path === "/divisions") {
      setState(true);
    } else {
      setState(false);
    }
  }, [data]);
  //HANDLE CHANGE CONCEPT COVERED
  // const handleChange = (event) => {
  //   setSearch(event.target.value);
  //   //movieData contains an array of movie documents
  //   const filteredMovies = moviedata.filter((movie) => {
  //     return movie.movie_title
  //       .toLowerCase()
  //       .includes(event.target.value.toLowerCase());
  //   });
  //   setSearchResult(filteredMovies); //setSearchResult is an array SEARCH RESULT HOLDS ALL MOVIES
  // };

  const handleChange = (e) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      setSearchResult([]);
    } else {
      // perform search and update searchResult state
      setSearch(e.target.value);
      //   //movieData contains an array of movie documents
      const filteredMovies = moviedata.filter((movie) => {
        return movie.movie_title
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      });
      setSearchResult(filteredMovies);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const filteredMovies = moviedata.filter((movie) => {
      return movie.movie_title.toLowerCase().includes(search.toLowerCase());
    });
    setSearchResult(filteredMovies);
  };

  const handleClear = () => {
    setSearch("");
    setSearchResult([]);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Enquiry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type="text" placeholder="Enter Phone Number" />
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>
          </Form>
          <a href="mailto:info@homescreenentertainment.ae">
            <p className="email">Email : info@homescreenentertainment.ae</p>
          </a>
          <Button className="header-cta" onClick={handleClose}>
            <a
              href="https://wa.me/971555555555?text=Hi%20I%20would%20like%20to%20enquire%20about%20your%20services"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="ri-whatsapp-line"></i> Whatsapp
            </a>
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button className="header-cta" onClick={handleClose}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <header id="main-header">
        <div className="main-header">
          <Container fluid>
            <Row>
              <Col sm="12">
                <Navbar expand="lg" className="p-0">
                  <Navbar.Toggle className="c-toggler">
                    <div className="navbar-toggler-icon">
                      <span className="navbar-menu-icon navbar-menu-icon--top"></span>
                      <span className="navbar-menu-icon navbar-menu-icon--middle"></span>
                      <span className="navbar-menu-icon navbar-menu-icon--bottom"></span>
                    </div>
                  </Navbar.Toggle>
                  <Navbar.Brand className="navbar-brand">
                    <Link to="/">
                      <img
                        className="img-fluid logo"
                        src={logo}
                        alt="streamit"
                        style={{ width: "190px", height: "50px" }}
                      />
                    </Link>
                  </Navbar.Brand>

                  <Dropdown className="mobile-more-menu">
                    <Dropdown.Toggle
                      onClick={setOpen}
                      as={CustomToggle}
                      variant="search-toggle"
                    >
                      <i className="ri-search-line"></i>
                    </Dropdown.Toggle>
                  </Dropdown>

                  <Navbar.Collapse>
                    <div
                      className="menu-main-menu-container"
                      style={{ paddingLeft: "0rem" }}
                    >
                      <Nav as="ul" id="top-menu" className="ml-auto">
                        <li className="menu-item">
                          <Link to="/" onClick={setData}>
                            Home
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/movies" onClick={setData}>
                            Movies
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/about-us" onClick={setData}>
                            About us
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/divisions" onClick={setData}>
                            Divisions
                          </Link>
                          {state === false ? (
                            <ul className="sub-menu">
                              <li className="menu-item">
                                <Link to="/film-distribution" onClick={setData}>
                                  Film Distribution
                                </Link>
                              </li>
                              <li className="menu-item">
                                <Link
                                  to="/film-negative-scanning"
                                  onClick={setData}
                                >
                                  Film Negative Scanning
                                </Link>
                              </li>
                              <li className="menu-item">
                                <Link to="/colorgrading" onClick={setData}>
                                  Colorgrading
                                </Link>
                              </li>
                              <li className="menu-item">
                                <Link to="/restoration" onClick={setData}>
                                  Restoration
                                </Link>
                              </li>
                              <li className="menu-item">
                                <Link to="/conversion" onClick={setData}>
                                  Conversion
                                </Link>
                              </li>
                              <li className="menu-item">
                                <Link to="/blueray" onClick={setData}>
                                  DVD, Blu-ray Mastering
                                </Link>
                              </li>
                              <li className="menu-item">
                                <Link to="/subtitling" onClick={setData}>
                                  Subtitling
                                </Link>
                              </li>
                              <li className="menu-item">
                                <Link to="/archiving" onClick={setData}>
                                  Archiving
                                </Link>
                              </li>
                            </ul>
                          ) : null}
                        </li>
                        <li className="menu-item">
                          {/*<Link to="/contact">Contact</Link>
                                                   <ul className="sub-menu">
                                                        <li className="menu-item"><Link to="/about-us">About Us</Link></li>
                                                        <li className="menu-item "><Link to="/contact">Contact</Link></li>
                                                        <li className="menu-item"><Link to="/faq">FAQ</Link></li>
                                                        <li className="menu-item"><Link to="/privacy-policy">Privacy-Policy</Link></li>
                                                        <li className="menu-item"><Link to="#">Pricing</Link>
                                                            <ul className="sub-menu">
                                                                <li className="menu-item"><Link to="/pricing-plan-1">Pricing Plan 1</Link></li>
                                                                <li className="menu-item"><Link to="/pricing-plan-2">Pricing Plan 2</Link></li>
                                                            </ul>
                                                        </li>
    </ul>*/}
                        </li>
                      </Nav>
                    </div>
                  </Navbar.Collapse>
                  <div
                    className="navbar-right menu-right"
                    // style={{
                    //   marginRight: "100px",
                    //   // border: "1px solid red",
                    //   // width: "70%",
                    //   justifyContent: "space-evenly",
                    // }}
                  >
                    <ul
                      className="d-flex align-items-left list-inline m-0"
                      style={{
                        // marginRight: "100px",
                        // border: "1px solid red",
                        // width: "100%",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div style={{ paddingTop: ".8rem" }}>
                        <li className="search-bar">
                          <Form style={{ paddingLeft: ".5rem" }}>
                            <Form.Group controlId="formBasicEmail">
                              <Form.Control
                                type="text"
                                placeholder="Search Movies"
                                value={search}
                                onChange={handleChange}
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  width: "200px",
                                  height: "30px",
                                  color: "#fff",
                                  fontWeight: "300",
                                }}
                              />
                            </Form.Group>
                          </Form>
                          {searchResult.length > 0 && (
                            <div
                              style={{
                                width: "250px",
                                backgroundColor: "#0f0f0f90",
                              }}
                            >
                              {searchResult.map((data) => (
                                <Table>
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        width: "70px",
                                        height: "70px",
                                        paddingTop: ".2rem",
                                      }}
                                    >
                                      <a href={`/movie/${data.slug}`}>
                                        {/* {data.thumbnail} */}
                                        <img
                                          src={data.thumbnail}
                                          className="img-fluid"
                                          alt=""
                                          style={{
                                            width: "60px",
                                            height: "60px",
                                            paddingLeft: ".4rem",
                                          }}
                                        />
                                      </a>
                                    </div>
                                    <div
                                      style={{
                                        paddingTop: ".2rem",
                                        textAlign: "left",
                                        paddingLeft: ".5rem",
                                      }}
                                    >
                                      <h5>
                                        <a
                                          href={`/movie/${data.slug}`}
                                          style={{
                                            display: "flex",
                                            color: "#e9692c", // F93943 backgroundColor: "#e9692c",
                                            fontSize: "17px",
                                          }}
                                        >
                                          {data.movie_title}
                                        </a>
                                      </h5>
                                      <p>
                                        <a
                                          href={`/movie/${data.slug}`}
                                          style={{
                                            color: "#ffffff",
                                            fontSize: "14px",
                                          }} //FF8C8C
                                        >
                                          {data.genres}
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </Table>
                              ))}
                            </div>
                          )}
                        </li>
                      </div>

                      {/* <Dropdown
                        as="li"
                        className="nav-item nav-icon"
                        style={{ paddingLeft: "1rem" }}
                      >
                        <Dropdown.Toggle
                          href="#"
                          as={CustomToggle}
                          variant="search-toggle"
                        ></Dropdown.Toggle>
                      </Dropdown> */}
                      <div>
                        <li
                          className="navbar-social-icons"
                          style={{
                            paddingTop: ".4rem",
                            

                            // border: "1px solid teal",
                          }}
                        >
                          <ul
                            className="info-share"
                            style={{ padding: "0px 10px 0px 10px " ,justifyContent:"space-evenly" ,display: "flex",
                             gap:"10px"}}
                          >
                            <li>
                              <a
                                href="https://www.facebook.com/homescreenentertainment/"
                                target="_blank"
                                // to="https://www.facebook.com/homescreenentertainment/"
                              >
                                <i className="fa fa-facebook"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href="https://twitter.com/homescreenent?lang=en"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path fill="#ffffff" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href="https://www.instagram.com/homescreenentfze/"
                              >
                                <i className="fa fa-instagram"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href="https://www.youtube.com/channel/UCR_sH8e1CnsRyhnPKTMzo6w"
                              >
                                <i className="fa fa-youtube-play"></i>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </div>
                      <div>
                        <li style={{ paddingTop: ".4rem" }}>
                          <Link to="/contact">
                            {" "}
                            <Button className="header-cta">Enquire Now</Button>
                          </Link>
                        </li>
                      </div>
                    </ul>
                  </div>
                  <div></div>
                </Navbar>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
};

export default HeaderStyle1;
