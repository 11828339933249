import React, { useState } from "react";
import { gsap } from "gsap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";
import { db } from "../../../firebase"; 
import "./css/style.css";
import { query, getDocs, collection } from "firebase/firestore";

const Moviecarousel = () => {
  const [moviedata, setMoviedata] = useState([]);
  const [hovering, setHovering] = useState(false);

  React.useEffect(() => {
    const fetchMovies = async () => {
      try {
        const q = query(collection(db, 'movie'));
        const querySnapshot = await getDocs(q);
        let movies = querySnapshot.docs.map(doc => ({
          id: doc.id,
          createTime: new Date(doc._document.createTime.timestamp.seconds * 1000), // Convert to Date object
          ...doc.data()
        }));
  
        // Sort descending by createTime
        movies.sort((a, b) => b.createTime - a.createTime);
  
        setMoviedata(movies);
      } catch (error) {
        console.error("Error fetching movies:", error);
      }
    };
  
    fetchMovies();
  }, []);
  

  const [swiper, setSwiper] = useState(null);

  const slideNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const slidePrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const slideTo = (index) => {
    if (swiper !== null) {
      swiper.slideTo(index);
    }
  };

  const onSwiper = (swiper) => {
    setSwiper(swiper);
  };

  const handleMouseOver = () => {
    setHovering(true);
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col sm="12" className="overflow-hidden">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="h4-latest">Latest Movies</h4>
              <Link
                className="iq-view-all"
                to="/movies"
                style={{ fontWeight: "400" }}
              >
                View All
              </Link>
            </div>
            <div id="favorites-contens">
              <div id="prev1" className="swiper-button swiper-button-prev">
                <i className="fa fa-chevron-left"></i>
              </div>
              <div id="next1" className="swiper-button swiper-button-next">
                <i className="fa fa-chevron-right"></i>
              </div>
              <Swiper
                navigation={{
                  prevEl: "#prev1",
                  nextEl: "#next1",
                }}
                breakpoints={{
                  320: { slidesPerView: 1 },
                  550: { slidesPerView: 2 },
                  991: { slidesPerView: 3 },
                  1400: { slidesPerView: 4 },
                }}
                loop={true}
                key={`swiper-${moviedata.length}`}
                slidesPerView={4}
                spaceBetween={20}
                as="ul"
                className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
              >
                {moviedata.map((data, index) => (
                  <SwiperSlide key={data.id} as="li">
                    <div
                      className="block-images position-relative"
                      onMouseOver={handleMouseOver}
                      onMouseOut={() => setHovering(false)}
                      style={{
                        cursor: "pointer",
                        transition: "all 0.3s ease-in-out",
                      }}
                    >
                      <div className="movie-thumb-new">
                        <img
                          src={data.thumbnail}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="block-description">
                        <h6 className="iq-title">
                          <Link to={`/movie/${data.slug}`}>
                            {data.movie_title}
                          </Link>
                          &nbsp;({data.language})
                        </h6>
                        <div
                          className="movie-time d-flex align-items-center my-3 iq-ltr-direction"
                          style={{ fontSize: "20px", opacity: ".8" }}
                        >
                          <span
                            className="badge badge-secondary p-0"
                            style={{
                              backgroundColor: "#3e536600",
                              opacity: "0.8",
                            }}
                          >
                            {data.genres}
                          </span>
                        </div>

                        {/* <div
                          className="hover-buttons"
                          style={{ paddingLeft: ".5rem" }}
                        >
                          {hovering === true ? (
                            <Link
                              to={`/movie/${data.slug}`}
                              role="button"
                              className="btn btn-hover iq-button"
                            >
                              <i
                                className="fa fa-play mr-1"
                                aria-hidden="true"
                              ></i>
                              View Details
                            </Link>
                          ) : null}
                        </div> */}
                        <div className="hover-buttons">
                          <Link
                            to={`/movie/${data.slug}`}
                            role="button"
                            className="header-cta"
                            style={{
                              fontWeight: 500,
                              width: "180px",
                              height: "30px",
                              color: "#fff",
                              padding: ".4rem",
                              borderRadius: ".3rem",
                            }}
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                      <div className="block-social-info">
                        <ul className="list-inline p-0 m-0 music-play-lists">
                          <li className="share">
                            <span>
                              <i className="ri-share-fill"></i>
                            </span>
                            <div className="share-box">
                              <div className="d-flex align-items-center">
                                <Link
                                  to={
                                    "//www.facebook.com/sharer?u=" +
                                    window.location.href
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="share-ico"
                                  tabIndex="0"
                                >
                                  <i className="ri-facebook-fill"></i>
                                </Link>
                                <Link
                                  to={
                                    "//twitter.com/intent/tweet?text=" +
                                    window.location.href
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="share-ico"
                                  tabIndex="0"
                                >
                                  <i className="ri-twitter-fill"></i>
                                </Link>
                                <Link
                                  to="#"
                                  data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                  className="share-ico iq-copy-link"
                                  tabIndex="0"
                                >
                                  <i className="ri-links-fill"></i>
                                </Link>
                              </div>
                            </div>
                          </li>
                          {/* <li>
                                             <span><i className="ri-heart-fill"></i></span>
                                             <span className="count-box">19+</span>
                                          </li>
                                          <li>
                                             <span><i className="ri-add-line"></i></span>
                                </li>*/}
                        </ul>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Moviecarousel;
