import React, { useState } from "react";
import { Container, Row, Col, Breadcrumb, Form } from "react-bootstrap";
import "../home/css/style.css";
// import CustomToggle from '../../../components/dropdowns'

//img
import aboutus1 from "../../../assets/images/division.png";
import { Link } from "react-router-dom";
import "./style.css";

const FilmNegativeScanning = () => {
  const [faq, setfaq] = useState("1");

  return (
    <>
      <div
        className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50"
        style={{ backgroundImage: `url(${aboutus1})` }}
      >
        <Container fluid>
          <Row className="align-items-center">
            <Col sm="12">
              <nav
                aria-label="breadcrumb"
                className="text-center iq-breadcrumb-two"
              >
                <h2 className="title">Film negative scanning</h2>
                <Breadcrumb className="main-bg">
                  <Breadcrumb.Item>Home</Breadcrumb.Item>
                  <Breadcrumb.Item>Divisions</Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    Film negative scanning
                  </Breadcrumb.Item>
                </Breadcrumb>
              </nav>
              <div className="col-lg-12">
                <h4 className="div-head-desc" style={{ fontWeight: "300" }}>
                  In this ‘digital’ era, film negatives are gradually becoming
                  obsolete, due to the convenience of Digital Cinema. Home
                  Screen Entertainment is one of the key players in the market
                  to offer the latest and most advanced film scanning services
                  to digitise your 35mm negative and positive film into DPX
                  Files with the highest ‘4K’ resolution.
                </h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <main id="main" className="site-main" style={{ paddingTop: 0 }}>
        <Container>
          <Row className="justify-content-center">
            {/* <div className="col-lg-12">
              <h4 className="div-head-desc" style={{fontWeight: '300'}}>
                In this ‘digital’ era, film negatives are gradually becoming
                obsolete, due to the convenience of Digital Cinema. Home Screen
                Entertainment is one of the key players in the market to offer
                the latest and most advanced film scanning services to digitise
                your 35mm negative and positive film into DPX Files with the
                highest ‘4K’ resolution.
              </h4>
            </div> */}
          </Row>
          <Row className="division-sub-contact">
            <Col lg="6">
              <div>
                <h4 className="head-div-sub">For more information</h4>
                <p>Home Screen Entertainment FZE</p>
                <p>Fujairah - Creative Tower, Dubai, UAE P.O Box 4422</p>
                <p>Phone: +97142387974</p>
                <p>Email: info@homescreenentertainment.ae</p>
              </div>
            </Col>
            <Col lg="6">
              <div>
                <h4 className="head-div-sub">Contact Us</h4>
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      style={{
                        backgroundColor: "#1e1e1e",
                        color: "#fff",
                        borderColor: "rgba(128, 128, 128, 0.5)",
                        fontWeight: "100",
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="Name">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      style={{
                        backgroundColor: "#1e1e1e",
                        color: "#fff",
                        borderColor: "rgba(128, 128, 128, 0.5)",
                        fontWeight: "100",
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="Phone">
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      style={{
                        backgroundColor: "#1e1e1e",
                        color: "#fff",
                        borderColor: "rgba(128, 128, 128, 0.5)",
                        fontWeight: "100",
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="Message">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Message"
                      style={{
                        backgroundColor: "#1e1e1e",
                        color: "#fff",
                        borderColor: "rgba(128, 128, 128, 0.5)",
                        fontWeight: "100",
                      }}
                    />
                  </Form.Group>
                  <button
                    className="header-cta"
                    style={{
                      fontSize: "16px",
                      color: "#fff",
                      padding: "70px 30px",
                      borderRadius: ".3rem",
                      fontWeight: 500,
                    }}
                  >
                    Submit
                  </button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
};

export default FilmNegativeScanning;
