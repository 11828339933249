import React from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Row, Col, Container, Button } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import Movieslistslide from './movies-slide';
import MovieGallery from './movie-gallery';
import '../home/css/style.css'


//img


import movie1 from '../../../assets/images/movies/01.jpg'
import movie2 from '../../../assets/images/movies/02.jpg'
import movie3 from '../../../assets/images/movies/03.jpg'
import movie4 from '../../../assets/images/movies/04.jpg'
import movie5 from '../../../assets/images/movies/05.jpg'
import movie6 from '../../../assets/images/movies/06.jpg'
import movie7 from '../../../assets/images/movies/07.jpg'
import movie8 from '../../../assets/images/movies/08.jpg'
import movie9 from '../../../assets/images/movies/09.jpg'
import movie10 from '../../../assets/images/movies/10.jpg'

// install Swiper modules
SwiperCore.use([Navigation]);

const MovieList = () => { 
    return (
        <> 
           {/* <section id="movieshow" className="gallery-mobile">
                <Movieslistslide />
              <Dropdown className="genres-box">
                    <Dropdown.Toggle as={Button} variant="secondary">
                        Genres
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="three-column">
                        <Dropdown.Item href="#">Hindi</Dropdown.Item>
                        <Dropdown.Item href="#">Tamil</Dropdown.Item>
                        <Dropdown.Item href="#">Punjabi</Dropdown.Item>
                        <Dropdown.Item href="#">English</Dropdown.Item>
                        <Dropdown.Item href="#">Comedies</Dropdown.Item>
                        <Dropdown.Item href="#">Action</Dropdown.Item>
                        <Dropdown.Item href="#">Romance</Dropdown.Item>
                        <Dropdown.Item href="#">Dramas</Dropdown.Item>
                        <Dropdown.Item href="#">Bollywood</Dropdown.Item>
                        <Dropdown.Item href="#">Hollywood</Dropdown.Item>
                        <Dropdown.Item href="#">Children & Family</Dropdown.Item>
                        <Dropdown.Item href="#">Award-Winning</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> 
            </section> */}
            <div className="main-content-gallery">
                <section id="iq-favorites">
                    <MovieGallery />
                </section>
            </div>
        </>
    )
}
export default MovieList;