import React, { useState } from 'react'
import { gsap } from 'gsap'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import { getSliderdata } from "../../../firebase"
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import '../home/css/style.css'


// install Swiper modules
SwiperCore.use([Navigation]);

const gsapAnimate = {
    getData: (elem) => {
        const option = {
            opacity: 0,
            scale: 1,
            position: {
                x: 0,
                y: 0,
            },
            ease: "",
            duration: 1,
            delay: .4,
            rotate: 0
        }
        if (elem !== undefined) {
            option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0)

            option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0)

            option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0)

            option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1)

            option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0)

            option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, .4)

            option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5)

            option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '')

            const setOption = { opacity: option.opacity, scale: option.scale, x: option.position.x, y: option.position.y, ease: option.ease, rotate: option.rotate, duration: option.duration, delay: option.delay }

            return setOption
        } else {
            return { opacity: 0 }
        }
    },
    onStart: (elem) => {

        const setOption = gsapAnimate.getData(elem)

        gsap.from(elem, setOption)

    },

    onEnd: (elem) => {

        const setOption = gsapAnimate.getData(elem)

        gsap.to(elem, setOption)

    },

    onStartEnd: (elem) => {

        const setOption = gsapAnimate.getData(elem)

        const setEndOption = gsapAnimate.getData(elem)

        setEndOption.opacity = 1

        setEndOption.x = 0

        setEndOption.y = 0

        setEndOption.rotate = 0

        setEndOption.scale = 1

        gsap.fromTo(elem, setOption, setEndOption)
    },
    validValue: (attr, defaultVal) => {
        if (attr !== undefined && attr !== null) {
            return Number(attr)
        }
        return Number(defaultVal)
    }
}

const Movieslistslide = () => {

    const [toggler1, setToggler1] = useState(false);
    const [sliderdata, setSliderdata] = React.useState([]);


    React.useEffect(() => {
        getSliderdata().then(data => {
            const PropData = [];
            data.forEach(item => {
                PropData.push(item?.data());
            });
            setSliderdata(PropData);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const animationInit = () => {
        if (document.querySelector('.swiper-container .swiper-slide-active') !== null) {
            const gsapElem = document.querySelector('.swiper-container .swiper-slide-active').querySelectorAll('[data-iq-gsap="onStart"]')
            Array.from(gsapElem, (elem) => {
                return gsapAnimate.onStartEnd(elem)
            })
        }
    }

    return (
        <React.Fragment>
            <div id="prev" className="swiper-button swiper-button-prev"><i className= "ri-arrow-left-s-line"></i></div>
                <div id="next" className="swiper-button swiper-button-next"><i className= "ri-arrow-right-s-line"></i></div>         
                <Swiper
                    slidesPerView={2}
                    spaceBetween={0}
                    centeredSlides={true}
                    navigation={{
                        prevEl: '#prev',
                        nextEl: '#next'
                    }}
                    loop={true}
                    className="">
                    {sliderdata.map(data => (   
                    <SwiperSlide key={data.id}>
                        <Link to={`/movie/${data.slug}`}>
                            <div className="shows-img-movieslide">
                                <img src={data.thumbnail} className="w-100 img" alt=""/>
                                <div className="shows-content">
                                    <h4 className="text-white mb-1">{data.movie_title}</h4>
                                    <div className="movie-time d-flex align-items-center">
                                        <div className="badge badge-secondary p-1 mr-2">{data.genres}</div>
                                        <span className="text-white">{data.starring}</span>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </SwiperSlide>        
                    ))}
                </Swiper>
        </React.Fragment>
    )
}
export default Movieslistslide;